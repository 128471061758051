import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { apiResponse } from 'src/app/models/apiResponse';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
 apiRoot:any = environment.baseUrl
  constructor(
    private _HttpClient: HttpClient
  ) { }

  postRequest<T>(url: string, body: any): Observable<apiResponse> {
    return this._HttpClient
      .post<apiResponse>(`${this.apiRoot}/${url}`, body)

  }

  getRequest<T>(url: string, isLoaderDisable = false): Observable<apiResponse> {
    if(isLoaderDisable) {
      const headers = new HttpHeaders()
      .append('X-Disable-Loader','true');
      return this._HttpClient.get<apiResponse>(`${this.apiRoot}/${url}`, { headers })
    }
    
    return this._HttpClient.get<apiResponse>(`${this.apiRoot}/${url}`)
  }

  putRequest<T>(url: string, body: any): Observable<T> {
    return this._HttpClient
      .put<T>(`${this.apiRoot}/${url}`, body)
  }

  deleteRequest<T>(url: string): Observable<T> {
    return this._HttpClient
      .delete<T>(`${this.apiRoot}/${url}`)

  }

  patchRequest<T>(url: string, body?:any): Observable<T> {
    return this._HttpClient
      .patch<T>(`${this.apiRoot}/${url}`,body)

  }
}
